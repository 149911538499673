<div class="logo">
    <a [routerLink]="" class="simple-text">
        <div class="p-d-flex p-justify-center">
          <span class="mr-3">CRM</span><img src="./assets/img/logo/deal-it-02.svg" alt="logo" style="width: 80px"/>
        </div>
    </a>

<!--  USER -->
  <mat-divider></mat-divider>
  <div class="row p-mx-2" style="margin-top: 15px">
    <div class="col-3"> <i class="pi pi-user"></i> </div>
    <div class="col-9 font-weight-bold">{{user?.name}}</div>
  </div>
  <div class="row p-mx-2" *ngIf="user?.coordinator?.id">
    <div class="col-3"> <i class="pi pi-users"></i> </div>
    <div class="col-9 font-weight-bold">{{user?.coordinator?.name}}</div>
  </div>
</div>
<!--  END USER -->

<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">

    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item dropdown">
        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLinkAccount" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Konto</span>
          </p>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLinkAccount">
          <a class="dropdown-item" routerLink="/home/myAccount">Moje konto</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Wyloguj</a>
        </div>
      </li>
    </ul>
  </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]" *ngxPermissionsOnly="menuItem.permission">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
