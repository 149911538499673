import { Injectable} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { CredentialsService } from './credentials.service';
import { Observable, of } from 'rxjs';
import { Credentials } from '../../shared/models/auth/Credentials';
import { map } from 'rxjs/operators';

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private ngxPermissionsService: NgxPermissionsService
  ) {
  }

  login(context: LoginContext): Observable<Credentials> {
    const data = {
      email: context.email,
      password: context.password,
      rememberMe: context.remember,

    };
    return this.http.post(`sanctum/token`, data).pipe(
      map(
        (res: any) => {
          const credentials: Credentials = {
            email: context.email,
            token: res.token.accessToken,
            rememberMe: context.remember,
            user: res,
          };
          this.credentialsService.setCredentials(credentials, context.remember);
          this.ngxPermissionsService.loadPermissions(credentials.user.roles.map(r => r.name));
          return res;
        },
        (err: any) => {
          return err;
        }
      )
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.http.get(`auth/logout`);
    this.credentialsService.setCredentials();
    this.ngxPermissionsService.flushPermissions();
    return of(true);
  }

  getToken(): string {
    return this.credentialsService.getToken();
  }

  getAuthHeader(): string {
    return this.credentialsService.getAuthHeader();
  }
}
