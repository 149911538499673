import { Component, OnInit } from '@angular/core';
import { CredentialsService } from './auth/services/credentials.service';
import { PrimeNGConfig } from 'primeng/api';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'crm';

  constructor(
    private credentialsService: CredentialsService,
    private ngxPermissionsService: NgxPermissionsService,
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit(): void {
    if (this.credentialsService.isAuthenticated()){
      this.credentialsService.setRoles().subscribe();
      this.setPermissions();
    }
    this.primengConfig.ripple = true;
  }

  /**
   * Ustawienie roli dla zalogowanego użytkownika
   */
  setPermissions(): void {
    const credentials = this.credentialsService.credentials;
    if (credentials) {
      this.ngxPermissionsService.loadPermissions(credentials.user.roles.map(r => r.name));
    }
  }
}
